import React, { useEffect, useState  } from 'react';
//import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "components/Layout";
import Seo from 'components/Seo';
import { StaticImage } from "gatsby-plugin-image"
import Banner from 'assets/svg/banner.svg';


const IndexPage = ({location, data}) => {

  return (
    <Layout>
      <Seo
        title={'Digital Transit Solutions'}
        pathname={location.pathname}
      />
      <section className="no-vertical-md center">
        <div  className="cont">
          <h1>اهلا بك في منصة تنقّل</h1>
          {/*<h1>منظومة النقل التشاركي</h1>*/}
          <p>لدينا وسائل جديدة للتنقّل اليومي بطريقة سهلة ومريحة. اخبرنا عن رحلتك وشاركنا الطريق.</p>
          <a href="/rider/1" role="button" >شاركنا الآن</a>
        </div>
      </section>
      <div className="banner">
        <div  className="cont">
          <Banner />
        </div>
      </div>
      <section>
        <div  className="cont">
            {/*<StaticImage placeholder="blurred" className="banner"  src={'../assets/media/banner.jpg'} alt="Oryx" width={700} />*/}
        </div>
      </section>
    </Layout>
  );
}

// export const query = graphql`
//   query {
//     markdownRemark(frontmatter: {title: {eq: "services"}}) {
//       html
//     }
//   }
// `

export default IndexPage
